<template>
    <div class="category">
        <v-progress-circular
            indeterminate
            color="primary"
            v-show="!loaded"
            class="fixed__preloader"
        ></v-progress-circular>
        <div v-show="loaded">
            <div class="category__back" @click="back"><v-icon>mdi-arrow-left-thin</v-icon> Назад</div>
            <template v-for="category in categories">
                <div class="category__item" :key="`category-${category.id}`" v-if="category.parent && category.parent.id === mainCategory.id">
                    <div class="category__item__ancor" :id="category.code"></div>
                    <div class="category__item__title">{{ category.title }}</div>
                    <div class="category__products-list">
                        <template v-for="product in category.products">
                            <ProductCard
                                :key="`product-${product.id}`"
                                :product="product"
                                @click="open(product)"
                            />
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, htmlFormatted } from '../helpers';
    import BaseButton from '../components/common/BaseButton';
    import BasePopup from '../components/common/BasePopup';
    import BaseCheckbox from '../components/common/BaseCheckbox';
    import BaseRadio from '../components/common/BaseRadio';
    import BaseCounter from '../components/common/BaseCounter';
    
    import ProductCard from '../components/entity/ProductCard';

    export default {
        name: 'Entity',
        metaInfo() {
            return {
                title: this.mainCategory.title
            }
        },
        components: {
            BaseButton,
            BasePopup,
            BaseCheckbox,
            BaseRadio,
            BaseCounter,
            ProductCard,
        },
        data: () => ({
            imageSrc, 
            priceFormated, 
            htmlFormatted,
            loaded: false,
            selectedSize: {},
            quantity: 1,
            popupSizeTableShown: false
        }),
        computed: {
            ...mapState('categories', {
                mainCategory: state => state.entity,
                categories: state => state.entities,
            }),
        },
        async mounted() {
            const { code } = this.$route.params;
            await store.dispatch('categories/getByCode', { code });
            await store.dispatch('categories/fetch', {});
            this.loaded = true;
        },
        methods: {
            open(product) {
                this.$router.push(`/product/${ product.code }`);
            },
            back() {
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="scss">
.category {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    &__preloading {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
    
    &__back {
        margin: 10px 0;
        cursor: pointer;
    }
        
    &__item {
        position: relative;

        &__title {
            padding: 10px;
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
        }

        &__ancor {
            position: absolute;
            top: -80px;
        }
    }

    &__products-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px 0 20px 0;
        margin-right: -20px;
        
        @media screen and (max-width: 890px) {
            margin-right: -10px;
        }

        > div {
            margin: 0 20px 20px 0;

            @media screen and (max-width: 890px) {
                margin: 0 10px 10px 0;
            }
        }
    }
}
</style>
